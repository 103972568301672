import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import ConfigAPI from '@/config/api'
import Session from '@/handler/session'

Vue.use(VueAxios, axios)

const instance = axios.create();

const servicesAPI = {  
  obtenerRemitos() {
    var url = ConfigAPI.baseURL + "purchase-remits" + Session.getToken();
    return instance.get(url);
  },
  mostrarRemitos(id) {
    var url = ConfigAPI.baseURL + "purchase-remits/" + id + Session.getToken();
    return instance.get(url);
  },
  agregarRemitos(data) {    
    var params = data
    var url = ConfigAPI.baseURL + "purchase-remits" + Session.getToken();
    return instance.post(url,params);
  },
  editarRemitos(data) {    
    var params = data    
    var url = ConfigAPI.baseURL + "purchase-remits/"+ data.id + Session.getToken();
    return instance.post(url,params);
  },
  eliminarRemitos(id) {
    var url = ConfigAPI.baseURL + "purchase-remits/"+ id + Session.getToken();
    return instance.delete(url);
  },  
  filtrarRemitos(data, page) {
    var params = data    
    var url = ConfigAPI.baseURL + "purchase-remits/filter/query" + Session.getToken() + "&page=" + page;
    return instance.post(url,params);
  },
  guardarPreciosRemito(data) {
    var params = data
    var url = ConfigAPI.baseURL + "purchase-remits/save/prices" + Session.getToken();
    return instance.post(url,params);
  },

  obtenerProveedores() {
    var url = ConfigAPI.baseURL + "suppliers" + Session.getToken();
    return instance.get(url);
  },  

  obtenerVoucherById(data) {
    var params = data    
    var url = ConfigAPI.baseURL + "sales-base/get-voucher/id" + Session.getToken();
    return instance.post(url,params);
  },
  obtenerVoucherBySupplier(data) {
    var params = data    
    var url = ConfigAPI.baseURL + "sales-base/get-voucher/supplier" + Session.getToken();
    return instance.post(url,params);
  },    
}

export default servicesAPI;